import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Message from "./pages/Message";
import Testmessage from "./pages/Testmessage";
import Splash from "./pages/Splash(1)";


function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/:access_key" element={<Layout />}>
          <Route index element={<Message />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
