import React, { useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import Popup from "../components/Popup";
import dhonipic from "./images/dhoni-message.png";
import dhonipic2 from "./images/messagescrendhon.png";
import dhonibgpic from "./images/dhoni.png";
import welcomescreendhonibgpic from "./images/Welcome back screen.png";
import logo from "./images/logo.svg";
import logoblack from "./images/Platinum_Logo-Black.png";
import playbtn from "./images/octicon_play.svg";
import closebtn from "./images/close.svg";
import replaybtn from "./images/replay.svg";
import whatsappicon from "./images/whatsapp.svg";
import instagramicon from "./images/instagram.svg";
import dhoni from "./images/dhoni_cutout.png";
import facebookicon from "./images/facebook.svg";
import linkedicon from "./images/linkdin.svg";
import youtubeicon from "./images/youtube.svg";
// import dhonimsgvideo from "./images/Dhoni_AV_Final_Retime_V1.mp4";
import axios from "axios";
import { useParams } from "react-router-dom";
import mutebtn from "./images/mutewhite.png";
import unmutebtn from "./images/VolumeButton.svg";
import "./Message.css";
import share from "../pages/images/share.png";

import DownloadIcon from "./images/download.png";

function Message() {
  const weburl = window.location.href;
  console.log("base url: ", weburl);

  const Baseurl = "https://api.msdhoni-menofplatinum.com";
  const { access_key } = useParams();

  const [showVideo, setShowVideo] = useState(false);
  const [videoFinish, setVideoFinish] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [username, setUsername] = useState("");
  const [uservideo_url, setUserVideo_url] = useState("");
  const [isVideoLoaded, setIsVideoLoaded] = useState(false); // New state for video load
  const [isSharing, setIsSharing] = useState(false);

  const videoRef = useRef(null);
  const playbtnRef = useRef(null);

  useEffect(() => {
    // Function to initialize Facebook SDK
    const initFacebookSDK = () => {
      if (window.FB) {
        window.FB.init({
          appId: "1533838420584133", // Replace with your Facebook App ID
          cookie: true,
          xfbml: true,
          version: "v12.0",
        });
        window.FB.AppEvents.logPageView();
      } else {
        // If FB is not available, wait and try again
        setTimeout(initFacebookSDK, 300);
      }
    };

    // Load Facebook SDK
    if (!document.getElementById("facebook-jssdk")) {
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = initFacebookSDK;
    } else {
      initFacebookSDK();
    }

    // Cleanup function
    return () => {
      // Remove the script tag if component unmounts
      const scriptTag = document.getElementById("facebook-jssdk");
      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
  }, []);

  // const shareOnFacebook = () => {
  //   console.log("triggered");

  //   if (window.FB) {
  //     window.FB.ui({
  //       method: 'share',
  //       href: "https://player.msdhoni-menofplatinum.com/PJ41JN1AA9PM",
  //       quote: 'This is a personalized message to share on Facebook!'
  //     }, function(response){
  //       if (response && !response.error_message) {
  //         console.log('Sharing succeeded');
  //         // You could add some user feedback here, e.g. alert('Shared successfully!');
  //       } else {
  //         console.log('Error while sharing:', response?.error_message);
  //         // You could add some user feedback here, e.g. alert('Sharing failed. Please try again.');
  //       }
  //     });
  //   } else {
  //     console.error('Facebook SDK not loaded');
  //     // You could add some user feedback here, e.g. alert('Unable to share. Please try again later.');
  //   }
  // };

  const shareOnFacebook = () => {
    const weburl = window.location.href;
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      weburl
    )}`;

    window.open(fbShareUrl, "_blank");
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      console.log("Video Ref:", videoRef.current);

      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
      }
    } else {
      console.log("Video element is not yet available.");
    }
  };

  const handleVolumeClick = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !videoRef.current.muted;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationStart(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleVideoEnd = () => {
    setVideoFinish(true);
    setShowPopup(true);
  };

  const handlePopupCloseBtn = () => {
    setShowVideo(false);
    setVideoFinish(false);
    setShowPopup(false);
    setIsPlaying(true);
  };

  const handleWatchAgainBtn = () => {
    setShowVideo(true);
    setVideoFinish(false);
    setShowPopup(false);
    setTimeout(() => handleVideoClick(), 200);
  };

  const handleLoadedMetadata = () => {
    setIsVideoLoaded(true);
    handleVideoClick();
  };

  useEffect(() => {
    const getuserdetails = async () => {
      try {
        const response = await axios.get(
          `${Baseurl}/api/get_user_details/?access_key=${access_key}`
        );
        console.log("User data API Response:", response.data);

        if (response.data.status === "success") {
          console.log("User video fetched successfully");
          setUsername(response.data.name);
          setUserVideo_url(response.data.video_url);
        } else {
          alert(
            "Something’s not right with the link. Please check the URL and try again."
          );
        }
      } catch (error) {
        console.error("Error:", error);
        // alert("Please check the URL")
      }
    };

    if (access_key) {
      console.log("Access key", access_key);

      getuserdetails();
    }
  }, []);

  // Function to share the video URL on social media
  // const shareOnSocialMedia = (platform) => {
  //   const videoUrl = uservideo_url;
  //   const weburl = window.location.href;

  //   const message = encodeURIComponent(
  //     `Hey ${username},\nThis is MS Dhoni - Man of Platinum.\nHere is a personalized video from me.\nClick here to watch: ${weburl}`
  //   );

  //   let shareUrl = "";

  //   switch (platform) {
  //     case "whatsapp":
  //       shareUrl = `https://wa.me/?text=${message}`;
  //       break;
  //     case "facebook":
  //       // Use window.open for consistency with other platforms
  //       shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(weburl)}&quote=${message}`;
  //       break;
  //     case "linkedin":
  //       shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(videoUrl)}`;
  //       break;
  //     case "youtube":
  //       shareUrl = `https://www.youtube.com/redirect?q=${encodeURIComponent(videoUrl)}`;
  //       break;
  //     default:
  //       return;
  //   }

  //   window.open(shareUrl, "_blank");
  // };

  const shareOnSocialMedia = async (platform) => {
    if (isSharing) {
      console.log("true");
      return;
    } // Prevent multiple clicks
    setIsSharing(true); // Set sharing state

    const videoUrl = uservideo_url;
    const weburl = window.location.href;

    const message = `Hey ${username},\nThis is MS Dhoni - Man of Platinum.\nHere is a personalized video from me.\nClick here to watch: ${weburl}`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: "Personalized Video from MS Dhoni",
          text: message,
        });
        console.log("Shared successfully");
      } else {
        let shareUrl = "";

        switch (platform) {
          case "whatsapp":
            shareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
            break;
          case "facebook":
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              weburl
            )}`;
            break;
          case "linkedin":
            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              videoUrl
            )}`;
            break;
          case "youtube":
            shareUrl = `https://www.youtube.com/redirect?q=${encodeURIComponent(
              videoUrl
            )}`;
            break;
          default:
            return;
        }

        window.open(shareUrl, "_blank");
      }
    } catch (error) {
      console.error("Sharing failed", error);
    } finally {
      setIsSharing(false); // Reset sharing state
    }
  };

  // Function to download the video
  const handleDownload = async () => {
    try {
      const response = await fetch(uservideo_url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "MSD_Message.mp4";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Error downloading the video:", error);
      window.open(uservideo_url);
    }
  };

  return (
    <div className="mainWrapper">
      {!showVideo ? (
        <div className="container">
          <div className="circle"></div>
          <div className="innerContainer">
            <img src={dhoni} alt="" />
          </div>
          <div className="vector"></div>
          <div className={"contentSectionNew"}>
            <div className="upperSection">
              <img src={logo} alt="" />
              <span className="primaryText">WELCOME BACK</span>
              <span className="secondaryText">
                Your exclusive, personalized message from our <br /> Man of
                Platinum,
                <strong> MS Dhoni</strong> has finally arrived!
              </span>
            </div>
     {username &&        <button
              onClick={() => {
                setShowVideo(true);
                setTimeout(() => handleVideoClick(), 200);
              }}
            >
              View Message
            </button>}
          </div>
        </div>
      ) : (
        <>
          {!videoFinish ? (
            <div
              className="video-container"
              style={{
                opacity: videoFinish ? "0" : "1",
                transition: "opacity 2s linear",
                // visibility: isVideoLoaded ? "visible" : "hidden",
              }}
            >
              <video
                ref={videoRef}
                onEnded={handleVideoEnd}
                // autoPlay
                muted={isMuted}
                onClick={handleVideoClick}
                // onLoadedMetadata={handleLoadedMetadata}
                playsInline
              >
                <source src={uservideo_url} type="video/mp4" />
              </video>

              <div
                className="absolute top-10 left-4 z-10 iconlogo"
                style={{ top: "10%" }}
              >
                <img className="h-14 object-contain" src={logo} alt="logo" />
              </div>

              <div
                className="absolute top-10 right-4 z-10 iconmute"
                style={{ top: "10%" }}
              >
                <img
                  className="h-6 w-6 cursor-pointer"
                  src={isMuted ? mutebtn : unmutebtn}
                  style={{ backgroundColor: "transparent" }}
                  alt="Volume Button"
                  onClick={handleVolumeClick}
                />
              </div>

              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                {isPlaying === true ? null : (
                  <img
                    ref={playbtnRef}
                    className={`h-16 w-16 cursor-pointer play-button`}
                    src={playbtn}
                    alt="Play Button"
                    onClick={handleVideoClick}
                  />
                )}
              </div>
            </div>
          ) : (
            showPopup && (
              <div
                style={{
                  backgroundImage: `url(${dhonibgpic})`,
                  backgroundSize: "cover",
                  height: "100vh",
                  margin: "auto",
                  width: "100%",
                }}
                // className="bg-no-repeat bg-cover w-110 h-full text-white relative"
                // className="ConclusionContainer"
              >
                <Popup bg="transparent">
                  <div className="w-full h-screen p-20 rounded-xl">
                    <img
                      className="absolute right-0 md:right-8 top-20 transform -translate-y-1/2"
                      src={closebtn}
                      onClick={handlePopupCloseBtn}
                      alt="Popup Close Button"
                      style={{ cursor: "pointer" }}
                    />
                    <img
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      src={replaybtn}
                      onClick={handleWatchAgainBtn}
                      alt="Rewatch Icon"
                      style={{ cursor: "pointer" }}
                    />
                    <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                      <small className="text-sm">Share on social media</small>
                      <div className="flex justify-center items-center gap-6 mt-2 cursor-pointer ">
                        <img
                          src={DownloadIcon}
                          alt="download"
                          width={"25rem"}
                          height={"25rem"}
                          onClick={handleDownload}
                        />
                        <img
                          src={share}
                          alt="share"
                          width={"25rem"}
                          height={"25rem"}
                          onClick={() => shareOnSocialMedia("native")}
                        />
                        {/* <button >Share via Native</button> */}

                        {/* <img
                          src={whatsappicon}
                          alt="WhatsApp"
                          onClick={() => shareOnSocialMedia("whatsapp")}
                        /> */}
                        {/* <img src={linkedicon} alt="LinkedIn" onClick={() => shareOnSocialMedia("linkedin")} />  */}
                        {/* <img
                          src={facebookicon}
                          alt="Instagram"
                          onClick={() => shareOnSocialMedia("facebook")}
                          // onClick={shareOnFacebook}
                        /> */}
                        {/* <img src={youtubeicon} alt="YouTube" onClick={() => shareOnSocialMedia("youtube")} /> */}
                      </div>
                    </div>
                  </div>
                </Popup>
              </div>
            )
          )}
        </>
      )}
    </div>
  );
}

export default Message;
